import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 720.000000 903.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,903.000000) scale(0.100000,-0.100000)">


<path d="M3902 6095 c-49 -11 -78 -23 -157 -63 -61 -31 -189 -135 -208 -168
-7 -12 -29 -48 -50 -79 -37 -55 -77 -157 -77 -195 0 -9 -3 -20 -7 -24 -10 -9
-14 -31 -20 -102 -6 -72 31 -232 73 -315 92 -183 246 -303 459 -360 75 -20
215 -32 215 -18 0 3 26 11 58 18 31 7 76 21 99 32 24 10 47 19 52 19 5 0 32
16 60 35 28 19 54 35 58 35 9 0 143 136 143 145 0 3 15 29 34 58 19 28 42 79
51 112 10 33 20 67 23 75 13 35 15 83 18 438 l4 372 -387 -1 c-279 0 -403 -4
-441 -14z"/>
<path d="M2640 4761 c-8 -5 -37 -12 -64 -16 -50 -7 -66 -12 -151 -50 -93 -42
-235 -151 -235 -181 0 -7 -9 -18 -20 -24 -11 -6 -20 -15 -20 -20 0 -5 -13 -32
-30 -58 -40 -66 -71 -151 -74 -204 0 -14 -4 -30 -8 -34 -5 -4 -8 -175 -8 -381
l0 -373 363 0 c374 1 476 9 542 44 17 9 35 16 41 16 7 0 35 16 64 35 29 19 55
35 59 35 14 0 148 148 171 190 45 83 59 115 71 165 6 28 17 61 23 74 6 13 11
63 10 110 0 106 -37 233 -97 339 -42 75 -156 195 -213 225 -169 89 -368 140
-424 108z"/>
<path d="M1795 3331 c-135 -35 -219 -148 -220 -291 0 -62 5 -85 28 -132 103
-209 354 -270 502 -123 21 21 49 63 62 92 33 71 30 183 -5 255 -34 70 -105
143 -167 173 -57 29 -147 40 -200 26z m190 -52 c52 -26 85 -60 118 -123 16
-31 22 -59 22 -116 0 -65 -4 -82 -31 -130 -36 -65 -99 -112 -175 -130 -146
-35 -300 78 -316 232 -13 128 68 250 188 287 51 16 141 6 194 -20z"/>
<path d="M1825 3171 c-47 -20 -60 -49 -60 -133 0 -77 1 -80 33 -109 22 -20 43
-29 67 -29 24 0 45 9 67 29 32 29 33 32 33 110 0 73 -2 83 -26 106 -27 28 -82
40 -114 26z m96 -71 c28 -15 27 -107 -2 -144 -29 -37 -76 -36 -90 2 -16 42 -2
108 28 132 29 23 37 24 64 10z"/>
<path d="M1206 3308 c-19 -18 -43 -80 -100 -259 -42 -130 -76 -241 -76 -246 0
-5 16 -28 36 -51 l36 -42 73 0 c84 0 98 8 116 70 11 34 16 40 41 40 18 0 28
-5 28 -15 0 -8 17 -32 38 -55 38 -39 41 -40 106 -40 85 0 106 11 106 54 0 33
-134 436 -164 495 -29 55 -61 71 -142 71 -62 0 -77 -3 -98 -22z m176 -22 c20
-19 171 -473 164 -491 -4 -11 -22 -15 -70 -15 -72 0 -87 11 -101 78 l-7 32
-63 0 -63 0 -12 -47 c-6 -26 -17 -51 -23 -55 -17 -11 -119 -10 -137 1 -12 8
-2 47 59 238 40 125 80 238 87 251 13 19 23 22 84 22 45 0 73 -5 82 -14z"/>
<path d="M1292 3115 c-6 -22 -13 -55 -17 -72 -6 -32 -6 -33 29 -33 35 0 35 1
29 33 -3 17 -11 50 -18 72 l-12 40 -11 -40z"/>
<path d="M2240 3310 c-19 -19 -20 -33 -20 -276 l0 -256 34 -34 c31 -31 39 -34
93 -34 32 0 63 4 69 8 21 14 34 51 35 99 l1 48 15 -43 c8 -23 30 -58 48 -77
l32 -35 105 0 c89 0 107 3 121 18 15 16 17 50 17 273 l0 255 -28 34 c-26 30
-35 34 -90 38 -55 4 -63 2 -86 -21 -19 -19 -26 -37 -26 -64 l0 -38 -19 41
c-33 72 -58 84 -177 84 -91 0 -107 -3 -124 -20z m231 -26 c8 -9 37 -91 66
-183 l52 -166 0 171 c1 118 5 174 13 182 16 16 100 16 116 0 17 -17 17 -479 0
-496 -8 -8 -46 -12 -105 -12 -110 0 -95 -19 -174 215 l-54 160 -3 -180 c-2
-128 -6 -181 -15 -187 -6 -4 -33 -8 -60 -8 -42 0 -49 3 -58 27 -6 16 -9 114
-7 250 2 186 6 225 18 233 8 5 56 10 106 10 73 0 94 -3 105 -16z"/>
<path d="M2856 3309 l-26 -20 0 -247 c0 -230 1 -249 20 -280 l20 -33 -53 -67
c-70 -87 -115 -154 -133 -197 -8 -19 -19 -35 -25 -35 -5 0 -7 -4 -4 -10 3 -5
-1 -10 -10 -10 -31 0 -117 -42 -106 -53 6 -6 21 -6 42 2 64 22 69 18 69 -53 0
-36 4 -67 8 -70 5 -3 9 14 9 37 1 24 8 59 16 79 17 41 39 48 154 48 66 0 74
-2 69 -17 -19 -64 -46 -232 -46 -286 0 -139 73 -100 168 90 58 113 50 191 -23
234 -19 12 -35 24 -35 29 0 4 11 61 24 126 37 186 40 218 21 234 -15 12 -23
11 -58 -8 -95 -50 -97 -44 -97 239 0 189 3 239 14 248 9 8 68 11 187 9 l174
-3 9 -27 c6 -18 6 -38 0 -55 l-9 -28 -118 -3 -117 -3 0 -44 0 -45 93 0 c103 0
117 -7 117 -55 0 -48 -14 -55 -117 -55 l-93 0 0 -45 0 -45 115 0 c114 0 116 0
127 -25 8 -18 9 -35 2 -53 -9 -26 -12 -27 -97 -30 l-87 -3 0 -34 0 -35 105 0
c126 0 145 11 145 85 0 37 -6 52 -35 84 -30 33 -33 40 -20 51 10 8 15 30 15
64 0 41 -5 57 -25 78 -32 34 -31 38 4 38 83 0 82 161 -1 206 -16 9 -74 13
-194 14 -158 0 -173 -2 -198 -21z m99 -691 c-14 -73 -28 -141 -31 -150 -4 -14
-21 -17 -99 -20 -52 -2 -95 0 -95 4 0 31 233 318 247 304 3 -2 -7 -64 -22
-138z m55 -268 c17 -32 11 -63 -29 -146 -44 -89 -66 -122 -76 -112 -7 6 41
273 51 290 7 11 42 -9 54 -32z"/>
<path d="M3588 3310 l-28 -21 0 -244 0 -243 -72 -21 c-243 -72 -393 -148 -426
-217 -15 -32 -15 -36 1 -51 15 -15 24 -15 126 7 157 33 219 20 263 -57 24 -40
35 -122 18 -133 -7 -4 -46 -14 -86 -21 -102 -18 -95 -33 11 -25 l85 7 0 -45
c0 -25 -7 -77 -15 -117 -16 -73 -12 -88 21 -89 6 0 26 61 44 136 18 75 38 137
44 139 95 31 247 95 305 128 40 23 105 71 143 106 93 86 129 160 107 217 -10
28 -10 28 10 9 12 -10 21 -24 21 -31 0 -30 23 -22 66 21 25 26 48 44 51 41 10
-9 -19 -66 -41 -81 -11 -8 -15 -15 -9 -15 6 0 -6 -43 -28 -99 -70 -178 -140
-502 -112 -519 6 -4 12 -16 14 -26 2 -11 11 -20 19 -21 12 -2 14 8 12 50 -9
141 82 468 189 677 40 78 37 74 51 60 7 -7 25 -12 40 -12 18 0 28 -5 28 -15 0
-8 17 -32 38 -55 38 -39 41 -40 106 -40 85 0 106 11 106 54 0 33 -134 436
-164 495 -29 55 -61 71 -141 71 -110 0 -108 3 -195 -272 -40 -128 -77 -237
-82 -242 -5 -6 -12 -6 -19 1 -9 9 -6 25 11 64 29 70 25 191 -9 257 -34 66 -96
128 -159 159 -51 25 -69 28 -187 31 -121 4 -132 3 -157 -18z m305 -30 c61 -23
109 -66 140 -124 29 -55 30 -174 3 -226 -39 -74 -50 -80 -147 -80 -49 0 -135
-9 -191 -20 -57 -11 -104 -20 -105 -20 -2 0 -3 105 -3 233 0 168 3 236 12 245
8 8 50 12 125 12 88 0 124 -4 166 -20z m569 6 c20 -19 171 -473 164 -491 -4
-11 -22 -15 -70 -15 -72 0 -87 11 -101 78 l-7 32 -64 0 c-35 0 -64 -3 -64 -7
0 -5 -20 -16 -45 -26 -25 -11 -56 -32 -69 -48 -26 -31 -53 -38 -62 -15 -6 15
130 448 152 484 13 19 23 22 84 22 45 0 73 -5 82 -14z m-413 -501 c17 -8 33
-26 37 -39 14 -58 -93 -178 -229 -258 -64 -38 -265 -123 -272 -116 -4 4 37
174 60 251 9 32 15 61 12 63 -5 6 -74 -167 -103 -259 -17 -54 -33 -61 -34 -14
0 33 -43 108 -75 132 -49 38 -119 47 -219 30 -46 -8 -92 -18 -102 -21 -12 -4
-6 6 16 28 19 19 38 33 43 31 4 -2 7 1 7 8 0 7 3 10 6 6 4 -3 23 2 44 13 54
28 259 97 357 121 207 50 385 59 452 24z"/>
<path d="M3730 3041 l0 -151 49 0 c80 0 131 58 131 150 0 91 -43 141 -125 148
l-55 4 0 -151z m130 59 c37 -37 19 -144 -29 -170 -38 -20 -41 -13 -41 90 l0
100 25 0 c14 0 34 -9 45 -20z"/>
<path d="M4372 3115 c-6 -22 -13 -55 -17 -72 -6 -32 -6 -33 29 -33 35 0 35 1
29 33 -3 17 -11 50 -18 72 l-12 40 -11 -40z"/>
<path d="M3835 2765 c-5 -2 -54 -11 -108 -20 -132 -23 -115 -38 40 -36 128 2
172 10 218 41 l30 20 -85 -1 c-47 0 -89 -2 -95 -4z"/>
<path d="M4646 3309 c-22 -17 -26 -28 -26 -71 0 -45 4 -54 39 -89 28 -29 47
-39 70 -39 l31 0 0 -166 0 -166 34 -34 c31 -31 39 -34 95 -34 99 0 101 3 101
220 l0 180 43 0 c23 0 52 7 64 15 48 33 37 133 -18 180 -30 25 -32 25 -218 25
-175 0 -190 -1 -215 -21z m402 -21 c7 -7 12 -29 12 -50 0 -46 -19 -58 -89 -58
l-51 0 -2 -197 -3 -198 -60 0 -60 0 -3 198 -2 197 -51 0 c-70 0 -89 12 -89 58
0 21 5 43 12 50 8 8 66 12 193 12 127 0 185 -4 193 -12z"/>
<path d="M5256 3308 c-19 -18 -43 -80 -100 -259 -42 -130 -76 -241 -76 -246 0
-5 16 -28 36 -51 l36 -42 73 0 c84 0 98 8 116 70 11 34 16 40 41 40 18 0 28
-5 28 -15 0 -8 17 -32 38 -55 38 -39 41 -40 106 -40 85 0 106 11 106 54 0 32
-135 437 -165 495 -28 55 -60 71 -141 71 -62 0 -77 -3 -98 -22z m176 -22 c20
-19 171 -473 164 -491 -4 -11 -22 -15 -70 -15 -72 0 -87 11 -101 78 l-7 32
-63 0 -63 0 -12 -47 c-6 -26 -17 -51 -23 -55 -17 -11 -119 -10 -137 1 -12 8
-2 47 59 238 40 125 80 238 87 251 13 19 23 22 84 22 45 0 73 -5 82 -14z"/>
<path d="M5342 3115 c-6 -22 -13 -55 -17 -72 -6 -32 -6 -33 29 -33 35 0 35 1
29 33 -3 17 -11 50 -18 72 l-12 40 -11 -40z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
